@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply overflow-x-hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--satoshi), system-ui, sans-serif;
    font-weight: 600 !important;
  }

  a {
    @apply text-clerk-blue;
  }
}

@layer components {
  .prose-clerk-blue a {
    @apply text-clerk-blue;
  }

  .prose-clerk-blue a:hover {
    @apply text-clerk-blue-hover;
  }

  .btn {
    /* font-size: 0.875rem ; */
    padding: 0.5rem 1.25rem !important;
    box-shadow: none !important;
    transition: all 0.2s;
    border-radius: 8px !important;
    border: none;
  }

  .btn:hover {
    background: #6c47ff !important;
  }

  .btn-outline {
    background: white !important;
    box-shadow: 0px 1px 2px rgba(57, 67, 86, 0.08) !important;
  }

  .btn-outline:hover {
    background: #e4e9f2 !important;
    color: #4c32b3 !important;
  }

  .btn-ghost {
    background: transparent !important;
  }

  .btn-ghost:hover {
    background: transparent !important;
  }
}

.prose a {
  text-decoration: none;
  word-break: break-word;
}

.text-image {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges;
}
.image-wrap {
  width: fit-content;
}
.image-wrap > div,
.image-wrap > span {
  display: block !important;
}

html,
body {
  --swiper-theme-color: #6c47ff !important;
  --swiper-navigation-size: 20px;
}

.swiper-container {
  padding-bottom: 2rem !important;
}

@media only screen and (max-width: 640px) {
  .swiper-pagination {
    display: none;
  }
}

.loader {
  position: relative;
  overflow: hidden;
}
.loader .loader-bar {
  position: absolute;
  border-radius: 10px;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  width: 0;
  animation: borealisBar 2s linear infinite;
  animation-timing-function: ease-in-out;
}
.loader .loader-bar.loader-reverse {
  animation-direction: reverse;
}

@keyframes borealisBar {
  0% {
    left: -100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}

.swiper {
  padding-bottom: 2rem !important;
}

/* Specific override for Prism code blocks in Markdown */
.markdown-code-block {
  background-color: transparent !important;
}

/* Specific override for main content section of tutorials */
.clerk-tutorial img {
  @apply rounded shadow-lg;
}

.slick-track {
  display: flex !important;
  height: 100%;
}

.slick-slide {
  height: auto !important;
}
.slick-slide > div {
  height: 100%;
}

.slick-arrow::before {
  display: none;
}

.slick-prev,
.slick-next {
  background: #6c47ff !important;
  border-radius: 50%;
  color: white !important;
  height: 50px !important;
  width: 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 9;
}

.slick-prev svg,
.slick-next svg {
  position: relative;
  top: 1px;
}

/* Animation styling for testimonial marquee on homepage */

.marquee-container:hover .marquee {
  animation-play-state: paused;
}
